import React, { useState } from "react";

import { getRemoteData } from "../components/utils";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

import hlvlogo from "../media/hlvLogo.png";
import { validateAuth } from "../components/utils";

import "./styles/login.css";
import md5 from "md5";


export default function LoginTab() {
	sessionStorage.clear();
	const [login] = useState(false);
	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [overlayNeeded, setoverlayNeeded] = useState(false);
	const history = useHistory();

    const validate=event=>{
        event.preventDefault();
        let credentials={email:md5(email),pass:md5(pass)}
        validateAuth(credentials).then((data) => {
			setoverlayNeeded(false);
			switch (data.data.count) {
                case 1:
					sessionStorage.setItem("hlvLoggedIn", true);
					history.push({
						pathname: '/listing'
                      })
                      break;
                default:
					toast.notify("Please login with valid credentials");
					break;
            }  
          })
        }

        const inputEmail=event=>{
            setEmail(event.target.value);
        }
        const inputPass=event=>{
            setPass(event.target.value);
        }
    
	return (
		<div className="container">
			<div className="header">
				<img src={hlvlogo} className="hlvLogo"></img>
			</div>

			<div className="loginContainer">
				<form noValidate onSubmit={validate}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
                        autoFocus
                        onChange={inputEmail}
						value={email}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
                        onChange={inputPass}
						value={pass}
					/>
					<Button type="submit"  fullWidth variant="contained" color="primary" className="submitButton">
						Sign In
					</Button>
				</form>
			</div>

			<div className="footer">
				<p>www.homevideos.com</p>
				<p>@2016-2020 HOME VIDEOS, All Rights Reserved</p>
			</div>
		</div>
	);
}
