import React, { Component } from "react";

import MaterialTable from "material-table";
import { getRemoteData } from "../components/utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";

import hlvlogo from "../media/hlvLogo.png";

import "./styles/listing.css";

export class Listing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cities: [],
			currentPage:0,
			columns: [
				{ title: "Address", field: "address" },
				{ title: "City", field: "city" },
				{ title: "Staging Date", field: "stagingDate", type: "date" },
				{ title: "Realtor", field: "realtor" },
			],
			listings: [],
			displayListings: [],
			isLoaded: false,
		};
	}

	filterCity = (values) => {
		let { listings } = this.state;
		//	let displayListings=listings.filter((listing)=> values.filter((value)=>value.id === listing.city))
		let displayListings = listings.filter((listing) => {
			let d = values.filter((value) => {
				return value.id === listing.city;
			});
			return d.length > 0 ? true : false;
		});
		this.setState({ displayListings });
	};

	changePage = (page) => {
 		this.setState({currentPage:Number(page)}) 
	}

	componentDidMount() {
		this.setState({ overlayNeeded: true, isLoaded: false });
		let page=0;
		getRemoteData("listings").then((listings) => {
			if (sessionStorage.getItem('hlvListPage') != undefined)
			{
				 page=Number(sessionStorage.getItem('hlvListPage'));
			}
			this.setState({
				listings: listings,
				displayListings: listings,
				overlayNeeded: false,
				currentPage:page,
				isLoaded: true,
			})
			getRemoteData("cities").then((cities) => {
				this.setState({
					cities:cities,
				});
			});
		});
		// api call for another data
	}

	render() {
		if (this.state.isLoaded === true) {
			return (
				<div className="container">
					<div className="header">
						<img src={hlvlogo} className="hlvLogo"></img>
					</div>
					<div className="listingTable">
						<Autocomplete
							multiple
							id="filterCities"
							className="filterCities"
							onChange={(event, newValue) => {
								this.filterCity(newValue);
							}}
							options={this.state.cities}
							getOptionLabel={(option) => option.label}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									label="Filter by Cities"
									placeholder="Cities"
								/>
							)}
						/>

						<MaterialTable
							components={{
								Container: (props) => <Paper {...props} elevation={0} />,
							}}
							columns={this.state.columns}
							data={this.state.displayListings}
							header={this.state.isLoaded}
							title=" "
							onChangePage={(currpage) => this.changePage(currpage)}
							options={{
								pageSize: 20,
								emptyRowsWhenPaging: false,
								actionsColumnIndex: -1,
								sorting: true,
								loadingType: "linear",
								initialPage:this.state.currentPage,
								headerStyle: {
									fontFamily: "Roboto",
									fontWeight: "bold",
									backgroundColor: "#f44336",
									color: "Beige"
								},
							}}
							actions={[
								{
									icon: VisibilityIcon,
									tooltip: "Site Details",
									onClick: (event, rowData) => {
										sessionStorage.setItem("hlvListPage",this.state.currentPage)
										this.props.history.push("/details", rowData);
									},
								},
							]}
						></MaterialTable>
					</div>
					<div className="footer">
						<p>www.homevideos.com</p>
						<p>@2016-2020 HOME VIDEOS, All Rights Reserved</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="container">
					<div className="header">
						<img src={hlvlogo} className="hlvLogo"></img>
					</div>
					<Skeleton
						variant="rect"
						animation="wave"
						width={"90%"}
						height={"100%"}
						className="skeleton"
					/>
					<div className="footer">
						<p>www.homevideos.com</p>
						<p>@2016-2020 HOME VIDEOS, All Rights Reserved</p>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(Listing);
