import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import axios from 'axios';
import download from "downloadjs"
 
sessionStorage.setItem("hlvListPage",0)


const environment = "remote";

let serverURL = "";
switch (environment) {
	case "local":
		serverURL = "http://localhost:3000/api/";
		break;
	case "remote":
		serverURL = "https://www.homelistingvideos.com/server/api/";
		break;
}

export const serverIp = serverURL;

var languages = [];

export const transactionTimeout = 10;

export const  getUTCDate = (dateString = Date.now()) => {
	const date = new Date(dateString);
  
	return new Date(
	  date.getUTCFullYear(),
	  date.getUTCMonth(),
	  date.getUTCDate(),
	  date.getUTCHours(),
	  date.getUTCMinutes(),
	  date.getUTCSeconds(),
	);
  };

  export const validateAuth=(credentials={})=>{
	return new Promise((resolve, reject) => {
		let data=dataIO("POST","login",credentials)
		resolve(data);
	});
  }


export const groupBy = function (arr, criteria) {
	return arr.reduce(function (obj, item) {
		// Check if the criteria is a function to run on the item or a property of it
		var key = typeof criteria === "function" ? criteria(item) : item[criteria];

		// If the key doesn't exist yet, create it
		if (!obj.hasOwnProperty(key)) {
			obj[key] = [];
		}

		// Push the value to the object
		obj[key].push(item);

		// Return the object to the next item in the loop
		return obj;
	}, {});
};

export const getRemoteData = (category) => {
	return new Promise((resolve, reject) => {
	
			const myHeaders = new Headers();
			myHeaders.append("Access-Control-Allow-Origin", "*");
			myHeaders.append("Content-Type", "application/json");

			let retdata = {};
			let url = serverIp  + category +"/";
			fetch(url, {
				method: "GET",
				headers: myHeaders,
			})
				.then((res1) => res1.json())
				.then((result1) => {
                    retdata = result1.data;
					resolve(retdata);
				})
				.catch((err) => {
					reject(err);
				});
		});
};

export const dataIO = (type,category,data) => {
	return new Promise((resolve, reject) => {
	
			const myHeaders = new Headers();
			myHeaders.append("Access-Control-Allow-Origin", "*");
			myHeaders.append("Content-Type", "application/json");

			let retdata = {};
			let url = serverIp  + category +"/";
			fetch(url, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(data)
			})
				.then((res) => res.json())
				.then((result) => {
					resolve(result);
				})
				.catch((err) => {
					reject(err);
				});
		});
};


export const getRemoteFile = (category,listid) => {
	return new Promise((resolve, reject) => {
	
			const myHeaders = new Headers();
			myHeaders.append("Access-Control-Allow-Origin", "*");
			myHeaders.append("Content-Type", "application/json");

			let retdata = {};
			let url = serverIp  + category + "/" + listid;
			let downloadFile=listid+"_photos.zip";

			axios({
				url:url,
				method:'get',
				headers:{
					'Content-Type': 'multipart/form-data',
					withCredentials:true,
		
		
				},
				responseType:'arraybuffer' // If we don't mention we can't get data in desired format
			})
			.then(async response => {
				let blob = await new Blob([response.data], { type: 'application/zip' }) //It is optional
		
				download(response.data,downloadFile,"application/zip") //this is third party it will prompt download window in browser.
		
				return response.data;
			})
	})
};

export const flatten = (arr) => {
	return arr.reduce(function (flat, toFlatten) {
		return flat.concat(
			Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
		);
	}, []);
};


export const returnDefaultValue = (array, defaultValue, returnNull = false) => {
	let fIndex = -1;
	fIndex = array.findIndex((x) => x.value == defaultValue); // Keeping it to == instead of === as the values in the array could be in a different format
	if (fIndex === -1) {
		if (returnNull === false) {
			fIndex = 0;
		} else {
			return null;
		}
	}
	return array[fIndex];
};

