import React from 'react';
import {BrowserRouter as Router, Switch,Route} from "react-router-dom";
import './styles/App.css';
import Listing from './Listing';
import Details from './Details';
import Login from './Login'
import ProtectedRoute from '../components/ProtectedRoute';




function App() {
    document.title = "Home Listing";
  
    return (
        <div className="App">
            <Router > 
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route exact={true} path="/" component={Login}/>
                  <ProtectedRoute  path="/listing" component={Listing}/>
                  <ProtectedRoute  path="/details" component={Details}/>
                </Switch>
            </Router>
        </div>
    )

}



export default App;
