import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import PermMediaIcon from "@material-ui/icons/PermMedia";

import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import ReactPlayer from "react-player";
import { Breadcrumbs } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

import hlvlogo from "../media/hlvLogo.png";

import LazyLoad from "react-lazy-load";
import Skeleton from "@material-ui/lab/Skeleton";
import {format} from "date-fns";

import "./styles/details.css";
import { getRemoteData, getRemoteFile, getUTCDate } from "../components/utils";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export class Details extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photos: [],
			videos: [],
			isLoaded: false,
			haveVideos: false,
			enableDownload: false,
			images: [],
			photoIndex: 0,
			showLightbox: false,
		};
	}

	componentDidMount() {
		this.setState({ overlayNeeded: true, isLoaded: false });
		let photos = [];
		let videos = [];
		let images = [];
		let currListing = this.props.location.state;
		getRemoteData("listingPhotos/" + currListing.id).then((data) => {
			data.map((image) => {
				photos.push({
					key: image.key,
					tn_key: "tn_" + image.key,
					src: image.img,
				});
				images.push(image.img);
			});
			this.setState({
				overlayNeeded: false,
				photos,
				isLoaded: true,
				enableDownload: true,
				images,
				currListing,
			});
		});
		getRemoteData("listingVideos/" + currListing.id).then((data) => {
			data.map((media) => {
				videos.push({ link: media.mediaLink });
			});
			if (videos.length > 0) {
				console.log(videos);
				this.setState({ videos: videos, haveVideos: true });
			}
		});
		// api call for another data
	}

	downloadPhotos = (event) => {
		this.setState({ enableDownload: false });
		getRemoteFile("downloadPhotos", this.state.currListing.id).then(() => {
			this.setState({ enableDownload: true });
		});
	};
	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		if (this.state.isLoaded === true) {
			let {
				photos,
				videos,
				currListing,
				showLightbox,
				photoIndex,
				images,
			} = this.state;
			let stagingDate=format(getUTCDate(currListing.stagingDate),"MM/dd/yyyy")
			return (
				<div className="container">
					<div className="header">
						<img src={hlvlogo} className="hlvLogo"></img>
					</div>
					<div className="wrapper">
						<div>
							<Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
								<Link
									color="inherit"
									onClick={this.goBack}
									className="link"
								>
									<HomeIcon className="icon" fontSize="small" />
									Listings
								</Link>
								<Typography className="link">
									<PermMediaIcon className="icon" fontSize="small" />
									Details
								</Typography>
							</Breadcrumbs>
							<div className="listingDetails">
								<div className="attributes">
									<p>{currListing.address}</p>
									<p>{currListing.realtor}</p>
									<p>{stagingDate}</p>
									<p>{currListing.Propertysize}</p>
									<p>{currListing.Realtorcompany}</p>
								</div>
							</div>
						</div>

						<div className="photoContainer">
							<nav className="lil-nav">
								{photos.map((photo) => (
									<LazyLoad
										className="thumbnail"
										key={photo.tn_key}
										debounce={false}
									>
										<a href={"#" + photo.key}>
											<img
												className="lil-nav__img"
												src={photo.src}
												alt={photo.src}
											/>
										</a>
									</LazyLoad>
								))}
							</nav>
							<div className="gallery">
								{photos.map((photo, photoIndex) => (
									<LazyLoad key={photo.key} debounce={false}>
										<img
											className="gallery__img"
											id={photo.key}
											src={photo.src}
											alt={photo.src}
											onClick={() => {
												this.setState({ photoIndex, showLightbox: true });
											}}
										/>
									</LazyLoad>
								))}
							</div>
						</div>

						{showLightbox && (
							<Lightbox
								mainSrc={images[photoIndex]}
								nextSrc={images[(photoIndex + 1) % images.length]}
								prevSrc={
									images[(photoIndex + images.length - 1) % images.length]
								}
								onCloseRequest={() => this.setState({ showLightbox: false })}
								onMovePrevRequest={() =>
									this.setState({
										photoIndex:
											(photoIndex + images.length - 1) % images.length,
									})
								}
								onMoveNextRequest={() =>
									this.setState({
										photoIndex: (photoIndex + 1) % images.length,
									})
								}
							/>
						)}

						<div className="downloader">
							<Button
								variant="contained"
								color="primary"
								onClick={this.downloadPhotos}
								disabled={!this.state.enableDownload}
							>
								Download Photos
							</Button>
						</div>
						<div
							className="videoContainer"
							className={this.state.haveVideos ? "videoContainer" : "hide"}
						>
							<ReactPlayer
								controls={true}
								url={videos.length > 0 ? videos[0].link : ""}
							/>
						</div>
					</div>
					<div className="footer">
						<p>www.homevideos.com</p>
						<p>@2016-2020 HOME VIDEOS All Rights Reserved</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="container">
					<div className="header">
						<img src={hlvlogo} className="hlvLogo"></img>
					</div>
					<Skeleton
						variant="rect"
						animation="wave"
						width={"90%"}
						height={"100%"}
						className="skeleton"
					/>
					<div className="footer">
						<p>www.homevideos.com</p>
						<p>@2016-2020 HOME VIDEOS All Rights Reserved</p>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(Details);
